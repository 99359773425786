.AboutGradiantTextColour {
    font-style: normal;
    font-size: 3rem;
    font-weight: 800;
    line-height: 105.02%;
    margin-bottom: -17px;
    background: linear-gradient(296.21deg, #ED3A58 -6.93%, #EFCA58 122.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.app-img{
    width: 25%;
}
.GradiantTextColour {
    font-style: normal;
    font-weight: 800;
    line-height: 105.02%;
    background: linear-gradient(296.21deg, #ED3A58 -6.93%, #EFCA58 122.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.GradiantTextColour2 {
    font-style: normal;
    font-weight: 800;
    line-height: 105.02%;
    background: linear-gradient(296.21deg, #ED3A58 -6.93%, #E37B48 100.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.cashBackText {
    background: linear-gradient(95.57deg, #4ECD6A 13.11%, #334F5D 54.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

.joinNowbg {
    background: linear-gradient(95.57deg, #ED3A58 13.11%, #E37B48 54.44%);
}

.custom-Badge {
    background: linear-gradient(278.88deg, #ED3A58 -5.39%, #E37B48 110.35%);
    border-radius: 0px 0px 12px 0px;
}


.loginButton {
    background: linear-gradient(95.57deg, #ED3A58 13.11%, #E37B48 54.44%);
    backdrop-filter: blur(1.43578px);
}

.blurBackground {
    background: linear-gradient(95.57deg, rgba(237, 58, 88, 0.2) 13.11%, rgba(227, 123, 72, 0.2) 54.44%);
    filter: blur(175px);
}

.blurBackGroundColor {
    background: linear-gradient(95.57deg, rgba(237, 58, 88, 0.2) 13.11%, rgba(227, 123, 72, 0.2) 54.44%);
    filter: blur(175px);
    -webkit-filter: blur(175px);
}
.react-countdown-simple{
  width: 100% !important;
}


@media screen and (max-width: 768px) {
    .app-img{
        width: 35%;
    }
}