ul {

    list-style: none;
 text-align: center;
}

li {position: relative;}

.nav {
    position: relative;
}

.nav:after {
    color: #ED3A58;
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
     background: linear-gradient(95.57deg, #ED3A58 13.11%, #E37B48 54.44%);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.nav:hover:after {
    width: 100%;
    left: 0;
    color: #ED3A58;
}